import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY, UUID_KEY } from '@auth/config'
import HTTP from '../lib/http'
import Cookies from 'js-cookie'
import { getToken } from '@plugin/login'
import basicFetch from '../lib/basic'

class Api extends HTTP {
  constructor() {
    super()
  }

  #getCookie(key) {
    return Cookies.get(key)
  }

  #getApiToken() {
    return { token: getToken(), uuid: this.#getCookie(UUID_KEY) }
  }

  async #proxy(method, url, params, ops) {
    return super[method](url, params, ops, this.#getApiToken()).catch(async err => {
      if (err && err.status === 403) {
        await refreshToken.refresh()
        return super[method](url, params, ops, this.#getApiToken())
      }
      return Promise.reject(err)
    })
  }

  async get(url, params, ops) {
    return this.#proxy('get', url, params, ops)
  }

  async post(url, params, ops) {
    return this.#proxy('post', url, params, ops)
  }

  async put(url, params, ops) {
    return this.#proxy('put', url, params, ops)
  }

  async delete(url, params, ops) {
    return this.#proxy('delete', url, params, ops)
  }
}

const httpClient = new Api()
export default httpClient

export function getHttpClient() {
  return new Api()
}

const refreshToken = {
  result: null,
  async refresh() {
    if (!this.result) {
      this.result = basicFetch('token/refresh', { refreshToken: Cookies.get(USER_REFRESH_TOKEN_KEY) }, 'POST').then(res => {
        const { token, refreshToken } = res || {}
        if (token && refreshToken) {
          const expires = new Date()
          expires.setFullYear(expires.getFullYear() + 1)
          Cookies.set(USER_TOKEN_KEY, token, { expires })
          Cookies.set(USER_REFRESH_TOKEN_KEY, refreshToken, { expires })
        }
        return res
      })
    }
    return this.result
  }
}